@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

:root {
    --primary-color: #2C974B;
    --secondary-color: #56AC6F;
    --dark-color: #033812;
    --light-color: #f4f4f4;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    font-family: 'Lato', sans-serif;
    color: #333;
    line-height: 1.6;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: #333;
}

button {
    text-decoration: none;
    color: #333;
}

h1, h2 {
    font-weight: 300;
    line-height: 1.2;
}

p {
    margin: 10px 0;
}

img {
    width: 100%;
}

.container {
    max-width: 1100px;
    margin: 0 auto;
    overflow: auto;
    padding: 0 40px;
}

.card {
    background-color: #fff;
    color: #333;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
    padding: 20px;
    margin: 10px;
}

.btn {
    display: inline-block;
    padding: 10px 30px;
    cursor: pointer;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 5px;
}

.btn-outline {
    background-color: transparent;
    border: 1px #fff solid;
}

.btn:hover {
    transform: scale(.98);
}

/* Backgrounds and colored buttons*/

.bg-primary, .btn-primary {
    background-color: var(--primary-color);
    color: #fff;
}

.bg-secondary, .btn-secondary {
    background-color: var(--secondary-color);
    color: #fff;
}

.bg-dark, .btn-dark {
    background-color: var(--dark-color);
    color: #fff;
}

.bg-light, .btn-light {
    background-color: var(--light-color);
    color: #333;
}

/* Text sizes */
.lead {
    font-size: 20px;
}

.sm {
    font-size: 1rem;
}

.md {
    font-size: 2rem;
}

.lg {
    font-size: 3rem;
}

.xl {
    font-size: 4rem;
}

.text-center {
    text-align: center;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

}

.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.grid-3 {
    grid-template-columns: repeat(3, 1fr);
}

/* Margin */
.m-1 {
    margin: 1rem;
}

.m-2 {
    margin: 1.5rem;
}

.m-3 {
    margin: 2rem;
}

.m-4 {
    margin: 3rem;
}

.m-5 {
    margin: 5rem;
}

.my-1 {
    margin: 1rem 0;
}

.my-2 {
    margin: 1.5rem 0;
}

.my-3 {
    margin: 2rem 0;
}

.my-4 {
    margin: 3rem 0;
}

.my-5 {
    margin: 5rem 0;
}

.py-1 {
    padding: 1rem 0;
}

.py-2 {
    padding: 1.5rem 0;
}

.py-3 {
    padding: 2rem 0;
}

.py-4 {
    padding: 3rem 0;
}

.py-5 {
    padding: 5rem 0;
}

.p-1 {
    padding: 1rem;
}

.p-2 {
    padding: 1.5rem;
}

.p-3 {
    padding: 2rem;
}

.p-4 {
    padding: 3rem;
}

.p-5 {
    padding: 5rem;
}

@media (max-width: 768px) {
    .grid {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}
