.showcase {
    height: 400px;
    background-color: var(--primary-color);
    color: #fff;
    position: relative;
}

.showcase h1 {
    font-size: 40px;
}

.showcase p {
    margin: 20px 0;
}

.showcase .grid {
    grid-template-columns: 53% 43%;
    grid-gap: 30px;
    overflow: visible;
}

.showcase-form {
    position: relative;
    top: 60px;
    height: 350px;
    width: 400px;
    padding: 40px;
    z-index: 100;
    justify-self: flex-end;
}

.showcase-form .form-control {
    margin: 30px 0;
}

.showcase-form input[type='text'],
.showcase-form input[type='email'] {
    border: 0;
    border-bottom: 1px solid #b4becb;
    width: 100%;
    padding: 3px;
    font-size: 16px;
}

.showcase-form input:focus {
    outline: none;
}

.showcase::before,
.showcase::after {
    content: '';
    position: absolute;
    height: 100px;
    bottom: -70px;
    right: 0;
    left: 0;
    background-color: #fff;
    transform: skewY(-3deg);
    -webkit-transform: skewY(-3deg);
    -moz-transform: skewY(-3deg);
    -ms-transform: skewY(-3deg);
}

.showcase-badge > a > img {
    width: 165px;
    height: 40px;
    margin: 10px 5px;
}

@media (max-width: 768px) {
    .showcase .grid {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    .showcase {
        height: auto
    }

    .showcase-text {
        text-align: center;
        margin-top: 40px;
    }

    .showcase-form {
        justify-self: center;
        margin: auto;
        width: 70%;
        padding: 40px;
    }
}

@media (max-width: 360px) {
    .showcase-form {
        width: 100%;
        padding: 20px;
    }

    .showcase .container {
        padding: 10px;
    }
}
