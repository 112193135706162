.episode {
    display: flex;
    justify-content: flex-start;

    width: 400px;
    height: auto;
    max-height: 360px;

    color: #333;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
    margin: 25px;
}

.episode .detail {
    flex-direction: column;
    padding: 20px;
}

.episode .no {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 32px;
    color: #fff;
    background: #2d2d2d;
    font-weight: bolder;

    margin-left: 0;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.episode i {
    margin-right: 3px;
}

.episode span {
    margin-right: 10px;
}

.episode .description {
    text-align: justify;
    text-justify: inter-word;
}

@media (max-width: 768px) {
    .episode {
        margin: 10px;
    }

    .episode .detail {
        padding: 15px;
    }
}
