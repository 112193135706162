@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --primary-color: #2C974B;
    --secondary-color: #56AC6F;
    --dark-color: #033812;
    --light-color: #f4f4f4;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    font-family: 'Lato', sans-serif;
    color: #333;
    line-height: 1.6;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: #333;
}

button {
    text-decoration: none;
    color: #333;
}

h1, h2 {
    font-weight: 300;
    line-height: 1.2;
}

p {
    margin: 10px 0;
}

img {
    width: 100%;
}

.container {
    max-width: 1100px;
    margin: 0 auto;
    overflow: auto;
    padding: 0 40px;
}

.card {
    background-color: #fff;
    color: #333;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
    padding: 20px;
    margin: 10px;
}

.btn {
    display: inline-block;
    padding: 10px 30px;
    cursor: pointer;
    background-color: #2C974B;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 5px;
}

.btn-outline {
    background-color: transparent;
    border: 1px #fff solid;
}

.btn:hover {
    -webkit-transform: scale(.98);
            transform: scale(.98);
}

/* Backgrounds and colored buttons*/

.bg-primary, .btn-primary {
    background-color: #2C974B;
    background-color: var(--primary-color);
    color: #fff;
}

.bg-secondary, .btn-secondary {
    background-color: #56AC6F;
    background-color: var(--secondary-color);
    color: #fff;
}

.bg-dark, .btn-dark {
    background-color: #033812;
    background-color: var(--dark-color);
    color: #fff;
}

.bg-light, .btn-light {
    background-color: #f4f4f4;
    background-color: var(--light-color);
    color: #333;
}

/* Text sizes */
.lead {
    font-size: 20px;
}

.sm {
    font-size: 1rem;
}

.md {
    font-size: 2rem;
}

.lg {
    font-size: 3rem;
}

.xl {
    font-size: 4rem;
}

.text-center {
    text-align: center;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

}

.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.grid-3 {
    grid-template-columns: repeat(3, 1fr);
}

/* Margin */
.m-1 {
    margin: 1rem;
}

.m-2 {
    margin: 1.5rem;
}

.m-3 {
    margin: 2rem;
}

.m-4 {
    margin: 3rem;
}

.m-5 {
    margin: 5rem;
}

.my-1 {
    margin: 1rem 0;
}

.my-2 {
    margin: 1.5rem 0;
}

.my-3 {
    margin: 2rem 0;
}

.my-4 {
    margin: 3rem 0;
}

.my-5 {
    margin: 5rem 0;
}

.py-1 {
    padding: 1rem 0;
}

.py-2 {
    padding: 1.5rem 0;
}

.py-3 {
    padding: 2rem 0;
}

.py-4 {
    padding: 3rem 0;
}

.py-5 {
    padding: 5rem 0;
}

.p-1 {
    padding: 1rem;
}

.p-2 {
    padding: 1.5rem;
}

.p-3 {
    padding: 2rem;
}

.p-4 {
    padding: 3rem;
}

.p-5 {
    padding: 5rem;
}

@media (max-width: 768px) {
    .grid {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}

.navbar {
    background-color: var(--primary-color);
    color: #fff;
    height: 70px;
}

.navbar ul {
    display: flex;
}

.navbar a {
    color: #fff;
    padding: 10px;
    margin: 0 5px;
}

.navbar a:hover {
    border-bottom: 2px #fff solid;
}

.navbar .flex {
    justify-content: space-between;
}

@media (max-width: 500px) {
    .navbar {
        height: 110px;
    }

    .navbar .flex {
        flex-direction: column;
    }

    .navbar ul {
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.1);
    }
}

.showcase {
    height: 400px;
    background-color: var(--primary-color);
    color: #fff;
    position: relative;
}

.showcase h1 {
    font-size: 40px;
}

.showcase p {
    margin: 20px 0;
}

.showcase .grid {
    grid-template-columns: 53% 43%;
    grid-gap: 30px;
    overflow: visible;
}

.showcase-form {
    position: relative;
    top: 60px;
    height: 350px;
    width: 400px;
    padding: 40px;
    z-index: 100;
    justify-self: flex-end;
}

.showcase-form .form-control {
    margin: 30px 0;
}

.showcase-form input[type='text'],
.showcase-form input[type='email'] {
    border: 0;
    border-bottom: 1px solid #b4becb;
    width: 100%;
    padding: 3px;
    font-size: 16px;
}

.showcase-form input:focus {
    outline: none;
}

.showcase::before,
.showcase::after {
    content: '';
    position: absolute;
    height: 100px;
    bottom: -70px;
    right: 0;
    left: 0;
    background-color: #fff;
    transform: skewY(-3deg);
    -webkit-transform: skewY(-3deg);
    -moz-transform: skewY(-3deg);
    -ms-transform: skewY(-3deg);
}

.showcase-badge > a > img {
    width: 165px;
    height: 40px;
    margin: 10px 5px;
}

@media (max-width: 768px) {
    .showcase .grid {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    .showcase {
        height: auto
    }

    .showcase-text {
        text-align: center;
        margin-top: 40px;
    }

    .showcase-form {
        justify-self: center;
        margin: auto;
        width: 70%;
        padding: 40px;
    }
}

@media (max-width: 360px) {
    .showcase-form {
        width: 100%;
        padding: 20px;
    }

    .showcase .container {
        padding: 10px;
    }
}

.season {
    padding-top: 100px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .season {
        margin: 0;
    }
}

.episode {
    display: flex;
    justify-content: flex-start;

    width: 400px;
    height: auto;
    max-height: 360px;

    color: #333;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
    margin: 25px;
}

.episode .detail {
    flex-direction: column;
    padding: 20px;
}

.episode .no {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 32px;
    color: #fff;
    background: #2d2d2d;
    font-weight: bolder;

    margin-left: 0;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.episode i {
    margin-right: 3px;
}

.episode span {
    margin-right: 10px;
}

.episode .description {
    text-align: justify;
    text-justify: inter-word;
}

@media (max-width: 768px) {
    .episode {
        margin: 10px;
    }

    .episode .detail {
        padding: 15px;
    }
}

