.season {
    padding-top: 100px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .season {
        margin: 0;
    }
}
